import React from "react";

interface LoadingOverlayProps {
  isLoading: boolean;
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <div className="min-w-screen fixed z-50 flex h-full min-h-screen w-full items-center justify-center bg-background opacity-60">
      <span className="loading loading-dots loading-lg text-success"></span>
    </div>
  );
};

export default LoadingOverlay;
