import { useLocation, useNavigate } from "@remix-run/react";
import { useTranslation } from "react-i18next";

import { IFloatingCompareBarProps } from "./types";

import Button from "~/components/atoms/Button";
import IconClose from "~/components/atoms/Icons/IconClose";
import Typo from "~/components/atoms/Typo";
import { CompareCard } from "~/components/molecules/CompareCard";
import { ECompareCardVariant } from "~/components/molecules/CompareCard/CompareCard.types";
import useAppState from "~/stores";
import { cn } from "~/utilities/cn";
import {
  COMPARE_PROJECT_SLUGS_KEY,
  PATH_NAME,
} from "~/utilities/constants/pathname";
import { MAX_NUM_OF_COMPARED_PROJECTS } from "~/utilities/constants/projects";
import { ETypoColor } from "~/utilities/enums/Colors";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";
import findFirstJpegSource from "~/utilities/helpers/findFirstImage";

const FloatingCompareBar: React.FC<IFloatingCompareBarProps> = ({
  classNameContainer = "",
  locale,
}) => {
  const { t } = useTranslation();
  const {
    comparedProjects,
    showComparedProjectsBar,
    removeComparedProject,
    updateComparedProjectsUrl,
    updateShowComparedProjectsBar,
  } = useAppState((state) => state);
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div
      className={cn(
        "relative bg-backgroundImage py-6 lg:py-3",
        classNameContainer,
        comparedProjects.length === 0 || !showComparedProjectsBar
          ? "hidden"
          : ""
      )}
    >
      <div className="container-fluid relative flex items-center gap-[40px] lg:flex-col lg:gap-3">
        <div className="flex flex-col items-center gap-3 lg:w-full lg:flex-row lg:justify-between lg:gap-3">
          <Typo
            tag={ETypoTag.H4}
            variant={ETypoVariant.HEADER_20}
            color={ETypoColor.TEXT}
            className="text-left text-header-20 font-bold capitalize lg:text-header-16"
          >
            {t("compare_projects")}
          </Typo>
          <div className="flex items-center gap-2">
            <IconClose
              className="hidden lg:block"
              onClick={() => updateShowComparedProjectsBar(false)}
            />
            <Button
              size="wide"
              className="w-160 border-none bg-color !py-2 text-body-title-16 font-bold capitalize text-white hover:bg-color lg:w-85 lg:py-1 lg:text-body-title-12"
              onClick={() => {
                updateComparedProjectsUrl(location.pathname);
                navigate(
                  `${PATH_NAME.COMPARE_PROJECTS.all(
                    locale
                  )}?${COMPARE_PROJECT_SLUGS_KEY}=${(
                    comparedProjects?.map(
                      (comparedProject) => comparedProject?.slug
                    ) || []
                  ).join(",")}`
                );
              }}
            >
              {t("compare")}
            </Button>
          </div>
        </div>
        <div className="flex-1 lg:w-full">
          <div className="grid auto-rows-fr grid-cols-6 gap-6 lg:hidden lg:gap-3">
            {comparedProjects.map((project) => (
              <CompareCard
                key={project.id}
                id={project?.id}
                variant={ECompareCardVariant.SMALL_ADDED}
                onRemove={() => removeComparedProject(project?.id)}
                thumbnail={findFirstJpegSource(
                  project?.photo ? [project?.photo] : []
                )}
                title={project.name}
              />
            ))}
            {comparedProjects.length < MAX_NUM_OF_COMPARED_PROJECTS && (
              <CompareCard
                variant={ECompareCardVariant.SMALL_EMPTY}
                containerClass="min-h-17"
              />
            )}
          </div>
          <div className="scroll scroll-x hidden flex-row flex-nowrap items-center justify-start gap-3 overflow-x-auto lg:flex">
            {comparedProjects.map((project) => (
              <div className="w-1/3 flex-shrink-0" key={project.id}>
                <CompareCard
                  id={project?.id}
                  variant={ECompareCardVariant.SMALL_ADDED}
                  onRemove={() => removeComparedProject(project?.id)}
                  thumbnail={findFirstJpegSource(
                    project?.photo ? [project?.photo] : []
                  )}
                  title={project.name}
                />
              </div>
            ))}
            {comparedProjects.length < MAX_NUM_OF_COMPARED_PROJECTS && (
              <div className="w-1/3 flex-shrink-0">
                <CompareCard
                  variant={ECompareCardVariant.SMALL_EMPTY}
                  containerClass="w-full min-h-17"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <IconClose
        className="absolute right-[87px] top-2 xl:right-0 lg:hidden"
        onClick={() => updateShowComparedProjectsBar(false)}
      />
    </div>
  );
};

export default FloatingCompareBar;
