import {
  Link,
  NavLink,
  useLocation,
  useMatches,
  useNavigate,
} from "@remix-run/react";
import isEmpty from "lodash/isEmpty";
import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import ContactUsButton from "../ContactUsButton";
import { IContentSection } from "../ContentsNavigator/types";

import IconMenuBar from "~/components/atoms/Icons/IconMenuBar";
import ImageResponsive from "~/components/atoms/ImageResponsive";
import Typo from "~/components/atoms/Typo";
import { IMediaImageUrls } from "~/entities/media";
import { IProject } from "~/entities/project";
import useScrollTopThreshold from "~/hooks/use-scroll-top-threshold";
import { IAgencySocial, ILink } from "~/types";
import { IMenuItem } from "~/types/menu";
import { cn } from "~/utilities/cn";
import { SCROLL_OFFSET } from "~/utilities/constants/projectSections";
import { ETypoColor } from "~/utilities/enums/Colors";
import { Slug } from "~/utilities/enums/Slug";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface HeaderMobileProps {
  logo?: IMediaImageUrls;
  menus: IMenuItem[];
  projectMenuItems: IContentSection[];
  phoneNumber?: string;
  socialLinks?: IAgencySocial[];
  topButton: ILink;
  top?: number;
  isSticky?: boolean;
  project?: IProject;
  areColorsSame?: boolean;
}

export type Ref = HTMLDivElement;

// eslint-disable-next-line react/display-name
const HeaderMobile = forwardRef<Ref, HeaderMobileProps>(
  (
    {
      logo,
      menus,
      projectMenuItems,
      topButton,
      top = 0,
      isSticky = false,
      project,
      areColorsSame = true,
    },
    ref
  ) => {
    const navigate = useNavigate();
    const location = useLocation();
    const isProjectDetailPage = /^\/projects\/[^/]+$/.test(
      location?.pathname || ""
    );
    const { t } = useTranslation();
    const buttonRef = useRef<HTMLDivElement | null>(null);
    const drawerIconRef = useRef<HTMLDivElement | null>(null);
    const drawerRef = useRef<HTMLDivElement | null>(null);
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [restSpace, setRestSpace] = useState<number>(0);
    const SPACING = 24;
    const PADDING = 52;
    const matches = useMatches();
    const scrollTop = useScrollTopThreshold();
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [currentSection, setCurrentSection] = useState<string | null>(null);
    const [projectMenu, setProjectMenu] = useState<IContentSection[]>();

    const isProjectPage = matches.some(
      (match) =>
        match.pathname.startsWith(`${Slug.PROJECTS}/`) &&
        match.pathname !== Slug.PROJECTS
    );

    // State to keep track of last scroll position
    useEffect(() => {
      if (scrollTop < lastScrollTop) {
        setOpenDrawer(false);
      }
      setLastScrollTop(scrollTop);
    }, [scrollTop, lastScrollTop]);

    useEffect(() => {
      if (buttonRef?.current && drawerIconRef?.current) {
        setRestSpace(
          buttonRef?.current?.offsetWidth +
            drawerIconRef?.current?.offsetWidth +
            SPACING +
            PADDING
        );
      }

      const handleClickOutside = (event: MouseEvent) => {
        if (
          drawerRef.current &&
          !drawerRef.current.contains(event.target as Node) &&
          !drawerIconRef.current?.contains(event.target as Node)
        ) {
          setOpenDrawer(false);
        }
      };

      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
          setOpenDrawer(false);
        }
      };

      if (openDrawer) {
        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("keydown", handleKeyDown);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
        document.removeEventListener("keydown", handleKeyDown);
      }

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        document.removeEventListener("keydown", handleKeyDown);
      };
    }, [openDrawer]);

    const scrollToView = (
      targetId: string,
      offset: number | undefined = SCROLL_OFFSET
    ) => {
      setOpenDrawer(false);
      const sectionEl = document.getElementById(targetId);
      if (sectionEl) {
        const y =
          sectionEl.getBoundingClientRect().top + window.scrollY + offset;
        window.scrollTo({
          top: y,
          behavior: "smooth",
        });
      }
      setCurrentSection(targetId);
    };

    useEffect(() => {
      if (projectMenuItems[0]?.targetId) {
        setProjectMenu(projectMenuItems);
        setCurrentSection(projectMenuItems[0].targetId);
      }
    }, [projectMenuItems]);

    const validProjectDataSlug = useMemo(() => {
      return (
        isProjectPage && !isEmpty(project?.name) && !isEmpty(project?.slug)
      );
    }, [isProjectPage, project?.name, project?.slug]);

    const scrollTo = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    return (
      <>
        <header
          ref={ref}
          className={cn(
            `sticky top-0 z-100 hidden flex-col gap-3  py-3 transition-all duration-400 lg:flex`,
            isSticky && "w-full border-b shadow-header",
            isProjectPage && areColorsSame
              ? `bg-backgroundPageProject`
              : `bg-headerBackground`
          )}
        >
          <div className="gap-1/2 container flex items-center justify-between px-6">
            {/* <Icon
              ref={drawerIconRef}
              TypeIcon={OpenMenuIcon}
              onClick={() => setOpenDrawer(!openDrawer)}
              className="cursor-pointer"
            /> */}
            <div ref={drawerIconRef}>
              <IconMenuBar
                onClick={() => setOpenDrawer(!openDrawer)}
                className="cursor-pointer"
              />
            </div>

            {!validProjectDataSlug && logo && (
              <Link to={Slug.HOME} className="shrink-0">
                <ImageResponsive
                  imageData={logo}
                  alt="Brand Logo"
                  className="h-[25.2px] w-auto origin-left "
                  zoom={1}
                  displayDisclaimer={false}
                />
              </Link>
            )}
            {validProjectDataSlug && (
              <button onClick={scrollTo}>
                <Typo
                  tag={ETypoTag.H1}
                  variant={ETypoVariant.HEADER_16}
                  color={ETypoColor.HEADER_TEXT}
                  className="truncate text-center lg:text-header-16"
                  style={{ width: `calc(100vw - ${restSpace}px)` }}
                >
                  {project?.name}
                </Typo>
              </button>
            )}
            <div ref={buttonRef}>
              <ContactUsButton
                label={topButton?.label || t("register_now")}
                url={topButton?.url}
              />
            </div>
          </div>
          <div
            ref={drawerRef}
            className={cn(
              "absolute z-100 w-full overflow-hidden bg-headerBackground px-6 shadow-header-mobile transition-all duration-300 ease-in-out",
              openDrawer ? "max-h-[100vh]" : "max-h-0"
            )}
            style={{ top: `${top}px` }}
          >
            <div className="flex">
              <div className="flex-1">
                {!isEmpty(menus) &&
                  menus.map((menu, index) => (
                    <NavLink
                      key={menu.href + index}
                      to={menu.href}
                      onClick={() => setOpenDrawer(false)}
                      className="flex items-center justify-start border-b border-headerDivider py-3 last-of-type:border-none"
                    >
                      {({ isActive, isPending }) => (
                        <Typo
                          tag={ETypoTag.H3}
                          variant={ETypoVariant.HEADER_14}
                          color={ETypoColor.HEADER_TEXT}
                          className={cn(
                            "text-center transition-all duration-200 hover:text-headerTextHover",
                            isPending || isActive
                              ? "font-bold text-headerTextActive"
                              : ""
                          )}
                        >
                          {menu.title}
                        </Typo>
                      )}
                    </NavLink>
                  ))}
              </div>

              {isProjectDetailPage && (
                <div className="flex-1">
                  {!isEmpty(projectMenu) &&
                    projectMenu?.map((section) => {
                      const active = currentSection === section.targetId;
                      return (
                        <div
                          key={section.targetId}
                          onClick={() => {
                            scrollToView(section.targetId, SCROLL_OFFSET);
                          }}
                          aria-hidden
                          className="flex items-center justify-start border-b border-headerDivider py-3 last-of-type:border-none"
                        >
                          <Typo
                            tag={ETypoTag.H3}
                            variant={ETypoVariant.HEADER_14}
                            color={ETypoColor.HEADER_TEXT}
                            className={cn(
                              "text-center transition-all duration-200 hover:text-headerTextHover",
                              active ? "font-bold text-headerTextActive" : ""
                            )}
                          >
                            {t(section.label)}
                          </Typo>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          </div>
        </header>
      </>
    );
  }
);

export default HeaderMobile;
