import { useMatches } from "react-router-dom";

import { TProjectLoaderData } from "~/types/projectLoaderData";

const useProjectRouteMatch = () => {
  const matches = useMatches();
  const projectRouteMatch = matches.find(
    (match) => match.id === "routes/($lang).projects.$slug"
  );
  const projectRouteData = projectRouteMatch?.data as TProjectLoaderData;
  const { project } = { ...projectRouteData };
  return { projectRouteMatch, project };
};

export default useProjectRouteMatch;
