import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive"; // Optional: For detecting tablet/mobile devices

import useAppState from "~/stores";

const useSticky = () => {
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const bannerRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  const { setBannerHeight, setHeaderHeight } = useAppState();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  useEffect(() => {
    // Function to update heights
    const updateHeights = () => {
      if (bannerRef.current) {
        setBannerHeight(bannerRef.current.offsetHeight);
      }
      if (headerRef.current) {
        setHeaderHeight(headerRef.current.offsetHeight);
      }
    };

    // Observe the intersection state of the header (or masthead)
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsSticky(!entry.isIntersecting && !isTabletOrMobile);
      },
      { threshold: 0 }
    );

    const mastheadCurrent = headerRef.current;
    if (mastheadCurrent) {
      observer.observe(mastheadCurrent);
    }

    // Update heights initially and on resize
    updateHeights();
    window.addEventListener("resize", updateHeights);

    return () => {
      if (mastheadCurrent) {
        observer.unobserve(mastheadCurrent);
      }
      window.removeEventListener("resize", updateHeights);
    };
  }, [setBannerHeight, setHeaderHeight, isTabletOrMobile]);

  return { isSticky, bannerRef, headerRef };
};

export default useSticky;
