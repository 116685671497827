import { useEffect } from "react";

const useAutoClose = (isOpen: boolean, onClose: () => void, time: number) => {
  useEffect(() => {
    if (!isOpen) return;

    const timer = setTimeout(() => {
      onClose();
    }, time);

    return () => clearTimeout(timer);
  }, [isOpen, onClose, time]);
};

export default useAutoClose;
