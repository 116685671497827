import { IColorScheme } from "~/types";

export const generateColorVariables = (
  colorSchemes: IColorScheme[]
): string => {
  if (!Array.isArray(colorSchemes)) {
    return "";
  }

  return colorSchemes
    .flatMap((scheme) =>
      Object.entries(scheme.colors).map(([key, value]) => `${key}: ${value};`)
    )
    .join(" ");
};
