import { useMemo } from "react";

import { IRouteLoaderData } from "~/types/routeLoaderData";

const useFaviconLinks = (loaderData: IRouteLoaderData) => {
  return useMemo(() => {
    const siteLogoUrl = loaderData?.siteLogo?.urls?.[0]?.url;
    const imageDomainUrl = loaderData?.ENV?.IMAGE_DOMAIN_URL;

    if (siteLogoUrl && imageDomainUrl) {
      const sizes = ["16x16", "192x192"];
      const hrefBase = `${imageDomainUrl}${siteLogoUrl}`;
      return sizes.map((size) => (
        <link key={size} rel="icon" href={hrefBase} sizes={size} />
      ));
    }

    return null;
  }, [loaderData?.ENV?.IMAGE_DOMAIN_URL, loaderData?.siteLogo?.urls]);
};

export default useFaviconLinks;
