import { EErrorCode } from "~/utilities/enums/ErrorCodes";

export interface IErrorConfig {
  message?: string;
  description?: string;
}

export default Object.freeze<Record<string, IErrorConfig>>({
  [EErrorCode[404]]: {
    message: `error.${EErrorCode[404]}.message`,
    description: `error.${EErrorCode[404]}.description`,
  },
  [EErrorCode[500]]: {
    message: `error.${EErrorCode[500]}.message`,
    description: `error.${EErrorCode[500]}.description`,
  },
});
