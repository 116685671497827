import React from "react";

export interface ContentGuardProps {
  children: React.ReactNode;
}

const ContentGuard: React.FC<ContentGuardProps> = ({ children }) => {
  return (
    <div className="relative">
      {children}
      <div className="pointer-events-none absolute inset-0 bg-transparent"></div>
    </div>
  );
};

export default ContentGuard;
