import { Links, Meta, Scripts, ScrollRestoration } from "@remix-run/react";
import React from "react";

interface ErrorProps {
  error?: {
    status?: number;
    statusText?: string;
    message?: string;
  };
  children?: React.ReactNode;
}

const ErrorComponent: React.FC<ErrorProps> = ({ error, children }) => {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <Meta />
        <Links />
        <title>
          {error?.status || 404} - {error?.statusText || "Not Found"}
        </title>
      </head>
      <body
        className="flex min-h-screen flex-col bg-background"
        suppressHydrationWarning={true}
      >
        <div className="flex flex-1 flex-col items-center justify-center bg-backgroundPageProject pt-2">
          {children}
        </div>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
};

export default ErrorComponent;
