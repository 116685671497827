import * as React from "react";
import { useCallback } from "react";
import { Alert, Modal } from "react-daisyui";
import { useTranslation } from "react-i18next";

import IconClose from "../Icons/IconClose";
import Typo from "../Typo";

import useAutoClose from "~/hooks/use-auto-close";
import useAppState from "~/stores";
import { cn } from "~/utilities/cn";
import { ETypoColor } from "~/utilities/enums/Colors";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export type TAlertProps = {
  title: string;
  variants: "success" | "error";
  time?: number;
  onClose: () => void;
};

const CustomAlert: React.FC<TAlertProps> = ({
  variants = "success",
  onClose,
}) => {
  const { t } = useTranslation();
  const { alertOpen, setAlert } = useAppState((state) => state);

  const handleClose = useCallback(() => {
    setAlert(false, "");
  }, [setAlert]);

  useAutoClose(alertOpen, handleClose, 5000);

  if (typeof window === "undefined") return <></>;

  if (!alertOpen) return <></>;

  return (
    <Alert
      className={cn(
        "fixed left-0 right-0 top-4 z-[999] mx-auto flex w-fit max-w-[80%] transform flex-col flex-nowrap items-start gap-6  rounded-3xl  border-none bg-contactUsModalBackground px-9 py-6 shadow-alert-form transition-all duration-300 lg:gap-5 lg:rounded-xl lg:px-[18px] lg:py-6",
        alertOpen ? "animate-animatetop" : "animate-animatetopExit"
      )}
      status={variants}
    >
      <Modal.Header className="mb-0 pb-0 font-bold">
        <div className="flex flex-row items-center justify-between gap-6">
          <Typo
            variant={ETypoVariant.HEADER_24}
            className="lg:text-sub-title-20"
            tag={ETypoTag.SPAN}
            color={ETypoColor.CONTACT_US}
          >
            {t("modal_confirm.showflat.heading")}
          </Typo>

          <IconClose onClick={() => onClose()} />
        </div>
      </Modal.Header>
      <Modal.Body className="flex items-start justify-start">
        <Typo
          variant={ETypoVariant.HEADER_18}
          tag={ETypoTag.SPAN}
          color={ETypoColor.CONTACT_US}
          className="whitespace-no-wrap  whitespace-pre-wrap text-left"
        >
          {t("modal_confirm.showflat.message")}
        </Typo>
      </Modal.Body>
    </Alert>
  );
};

export default CustomAlert;
